import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default function SiteMap () {
    const[pages, getPages] = useState(null)
    return (
        <StaticQuery
            query = {graphql`
            {
                allSitePage {
                    totalCount
                    nodes {
                    path
                    }
                }
            }
        `}
            render = {data => (
                <div id="site_pages">
                    {getPages(data.allSitePage.nodes)}
                    {data.allSitePage.nodes && data.allSitePage.nodes.map((page, index) => (
                        <ul id="pages" key={index}>
                            <Link to={page.path}><li className="page">{page.path}</li></Link>
                        </ul>
                    ))}
                </div>
            )}
        />
    )
}





