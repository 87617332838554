import React from 'react';
import SiteMap from '../components/SiteMap'
import Header from '../components/Header'
import '../styles/sitemap.css'
import Footer from '../components/Footer';

const sitemap = () => {

    return(
        <div id="sitemap">
            <Header />
            <SiteMap />
            <Footer />
        </div>
    )
}

 
export default sitemap;